import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'
import jwtDecode from "jwt-decode";
import { Provider } from 'react-redux'
import store from './store'

import axios from 'axios'

axios.interceptors.request.use(function (config) {
  if(localStorage.getItem('token')) {
    config.headers.common.authorization = 'Bearer ' + localStorage.getItem('token')
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  if(response.data?.token){
    const claims = jwtDecode(response.data?.token);
    if(claims.role=== 1) {
      localStorage.setItem('token', response.data?.token)
    }
  }
  return response;
}, function (error) {
  if(error.response?.status === 403 || error.response?.status === 401){
    localStorage.removeItem('token')
    window.location.href = "/";
    return;
  }
  return Promise.reject(error);
});

React.icons = icons

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
